<template>
    <div class="base-box">
        <el-form ref="businessInfoform" :model="businessInfo" label-width="140px" :rules="rules">
            <div class="images-box">
                <el-form-item prop="licenseImg">
                    <div class="images-item">
                        <div class="images-uploader">
                            <OssUpload @change="getLicenseImg" suffix="gif;jpg;png" :oldImg="businessInfo.licenseImg" ocrType="BUSINESS_LICENSE" :isUpdate="update" :isOneImg="true"></OssUpload>
                        </div>
                        <div class="images-title"><span>*</span>营业执照照片</div>
                    </div>
                </el-form-item>
                <el-form-item prop="premisesImg">
                    <div class="images-item">
                        <div class="images-uploader">
                            <OssUpload @change="getPremisesImg" suffix="gif;jpg;png" :oldImg="businessInfo.premisesImg" :isUpdate="update" :isOneImg="true"></OssUpload>
                        </div>
                        <div class="images-title"><span>*</span>经营场所室内照片</div>
                    </div>
                </el-form-item>
            </div>
            <div class="images-box">
                <el-form-item prop="doorImg">
                    <div class="images-item">
                        <div class="images-uploader">
                            <OssUpload @change="getDoorImg" suffix="gif;jpg;png" :oldImg="businessInfo.doorImg" :isUpdate="update" :isOneImg="true"></OssUpload>
                        </div>
                        <div class="images-title"><span>*</span>经营场所门头照</div>
                    </div>
                </el-form-item>
                <el-form-item prop="securitiesImg">
                    <div class="images-item">
                        <div class="images-uploader">
                            <OssUpload @change="getSecuritiesImg" suffix="gif;jpg;png" :oldImg="businessInfo.securitiesImg" :isUpdate="update" :isOneImg="true"></OssUpload>
                        </div>
                        <div class="images-title"><span>*</span>经营场所产权证明</div>
                    </div>
                </el-form-item>
            </div>
            <el-form-item label="企业名称" prop="companyName">
                <el-input v-model="businessInfo.companyName"></el-input> 
            </el-form-item>
            <el-form-item label="统一信用代码" prop="busiLicenseCode">
                <el-input v-model="businessInfo.busiLicenseCode"></el-input>
            </el-form-item>
            <el-form-item label="营业执照有效期" prop="busiLicensePeriod">
                <el-col :span="16">
                    <el-date-picker placeholder="选择日期" v-model="businessInfo.busiLicensePeriod" value-format="yyyy-MM-dd" :disabled="foreverCheck" type="date" style="width: 100%;"></el-date-picker>
                </el-col>
                <el-col :span="5" style="padding-left:20px">
                    <el-checkbox v-model="foreverCheck" @change="businessInfo.busiLicensePeriod='0'">永久有效</el-checkbox>
                </el-col>
            </el-form-item>
            <el-form-item label="法人代表姓名">
                {{legalName}}
            </el-form-item>
            <el-form-item label="驳回原因" required v-if="checkMsg">
                <el-input type="textarea" v-model="checkMsg"></el-input>
            </el-form-item>
        </el-form>
        <el-row :gutter="20">
            <el-col :span="12" :offset="6">
                <el-button  type="primary" @click="prevClick">上一步</el-button>
                <el-button type="primary"  @click="nextClick('businessInfoform')">下一步</el-button>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import {formatOCRDate } from '@/utils/tools'
  export default {
    data() {
        var checkLicenseImg = (rule, value, callback) => {
            if (!this.businessInfo.licenseImg) {
            return callback(new Error('请上传营业执照照片'));
            } else{
                callback();
            }
        };
        var checkPremisesImg = (rule, value, callback) => {
            if (!this.businessInfo.premisesImg) {
            return callback(new Error('请上传经营场所室内照片'));
            } else{
                callback();
            }
        };
        var checkDoorImg = (rule, value, callback) => {
            if (!this.businessInfo.doorImg) {
            return callback(new Error('请上传经营场所门头照'));
            } else{
                callback();
            }
        };
        var checkSecuritiesImg = (rule, value, callback) => {
            if (!this.businessInfo.securitiesImg) {
            return callback(new Error('请上传经营场所产权证明'));
            } else{
                callback();
            }
        };
        var checkDate = (rule, value, callback) => {
            if (!value&&!this.foreverCheck) {
            return callback(new Error('有效期不能为空'));
            } else{
                callback();
            }
        }
      return {
        businessInfo: {},
        rules: {
          licenseImg: [{ validator : checkLicenseImg, trigger: 'change' }],
          premisesImg: [{ validator : checkPremisesImg, trigger: 'change' }],
          doorImg: [{ validator : checkDoorImg, trigger: 'change' }],
          securitiesImg: [{ validator : checkSecuritiesImg, trigger: 'change' }],
          companyName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
          busiLicenseCode: [ { required: true, message: '请输入统一信用代码', trigger: 'blur' } ],
          busiLicensePeriod: [ { validator: checkDate, trigger: 'change' } ],
        },
        foreverCheck: false
      }
    },
    props:{
        legalName:{
            type: String,
            default:''
        },
        update:{
            type: Boolean,
            default: false
        },
        updateData:{
            type: Object,
            default: {}
        },
        checkMsg:{
            type: String,
            default: ''
        }
    },
    watch:{
        updateData:{
            handler(val){
                if(val&&this.update){
                    this.businessInfo = val
                    if(this.businessInfo.busiLicensePeriod=='0'){
                        this.foreverCheck = true
                    }
                }
            },
            immediate:true
        }
    },
    methods: {
        getLicenseImg(fileList,filename,ocrData){
            if(JSON.stringify(ocrData) != '{}'){
                this.$set(this.businessInfo,'companyName',ocrData['单位名称'].words)
                this.$set(this.businessInfo,'busiLicenseCode',ocrData['社会信用代码'].words)
                if(ocrData['有效期'].words=='无'){
                    this.foreverCheck = true
                    this.businessInfo.busiLicensePeriod='0'
                } else{
                    this.foreverCheck = false
                    this.$set(this.businessInfo,'busiLicensePeriod',formatOCRDate(ocrData['有效期'].words))
                }
            }
            this.businessInfo.licenseImg = filename
        },
        getPremisesImg(fileList,filename){
            var file = filename.slice(-1)
            this.businessInfo.premisesImg = filename
        },
        getDoorImg(fileList,filename){
            var file = filename.slice(-1)
            this.businessInfo.doorImg = filename
        },
        getSecuritiesImg(fileList,filename){
            var file = filename.slice(-1)
            this.businessInfo.securitiesImg = filename
        },
        nextClick(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$emit('change',this.businessInfo,'next')
                } else {
                    return false;
                }
            });
        },
        prevClick(){
            this.$emit('change','','prev')
        }

    }
  }
</script>