<template>
    <div class="base-box">

        <el-form ref="setterInfoform" :model="setterInfo" label-width="140px" :rules="rules">
            <el-form-item label="账户类型">
                <el-select v-model="setterInfo.accountType" placeholder="请选择账户类型" @change="getAccountType">
                    <el-option label="对私" value="1"></el-option>
                    <el-option label="对公" value="2"></el-option>
                </el-select>
            </el-form-item>
            <div class="images-box">
                <div class="images-item">
                    <el-form-item prop="accountImg">
                        <OssUpload @change="getAccountImg" suffix="gif;jpg;png;jpeg" :oldImg="setterInfo.accountImg"
                            ocrType="BANK_CARD" :isUpdate="update" :isOneImg="true"></OssUpload>
                        <div class="images-title"><span>*</span>{{ accountTypeTxt }}</div>
                    </el-form-item>

                </div>
            </div>
            <el-form-item label="开户名" prop="accountName">
                <template v-if="setterInfo.accountType == '1'">
                    {{ legalName }}
                </template>
                <template v-else>{{ companyName }}</template>
            </el-form-item>
            <el-form-item label="结算账户号码" prop="accountNo">
                <el-input type="tel" v-model="setterInfo.accountNo" @blur="remoteMethod"></el-input>
            </el-form-item>
            <el-form-item label="开户行城市">
                <div style="float:left">
                    <el-form-item prop="accountProvince">
                        <el-select v-model="setterInfo.accountProvince" placeholder="请选择省份" @change="provinceChange">
                            <el-option v-for="item in provinceList" :key="item.code" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div style="float:left; padding:0 10px" :span="1"> - </div>
                <div style="float:left">
                    <el-form-item prop="accountCity">
                        <el-select v-model="setterInfo.accountCity" placeholder="请选择城市">
                            <el-option v-for="item in cityList" :key="item.code" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </el-form-item>
            <el-form-item label="开户银行" prop="bankName">
                <el-select style="width:400px" v-model="setterInfo.bankName" filterable remote reserve-keyword
                    placeholder="请输入开户银行" :remote-method="remoteMethod" @change="getBank" :loading="loading">
                    <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="支行名称" prop="branchName">
                <el-select style="width:400px" @focus="checkPC" v-model="setterInfo.branchName" filterable remote
                    reserve-keyword placeholder="请输入支行名称" :remote-method="branchRemoteMethod" @change="getCnaps"
                    :loading="loading">
                    <el-option v-for="item in branchOptions" :key="item.id" :label="item.name" :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="联行号">
                {{ setterInfo.cnapsNo }}
            </el-form-item>
            <el-form-item label="驳回原因" required v-if="checkMsg">
                <el-input type="textarea" v-model="checkMsg"></el-input>
            </el-form-item>
        </el-form>
        <el-row :gutter="20">
            <el-col :span="12" :offset="6">
                <el-button type="primary" @click="prevClick">上一步</el-button>
                <el-button type="primary" @click="nextClick('setterInfoform')">{{ update ? '提交' : '下一步' }}</el-button>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { AgentApi } from '@/api'
export default {
    data() {
        var checkAccountImg = (rule, value, callback) => {
            if (!this.setterInfo.accountImg) {
                return callback(new Error('请上传照片'));
            } else {
                value = this.setterInfo.accountImg
                callback();
            }
        };
        return {
            setterInfo: { accountType: '1', accountCity: '', accountName: '', branchName: '', bankName: '' },
            accountTypeTxt: '法人代表银行卡正面照片',
            accountImg: '',
            accountImg2: '',
            options: '',
            branchOptions: '',
            bankCode: '',
            cityList: [],
            loading: false,
            rules: {
                accountImg: [{ validator: checkAccountImg, trigger: 'change' }],
                accountNo: [{ required: true, message: '请输入结算账户号码', trigger: 'blur' }],
                accountProvince: [{ required: true, message: '请选择省份', trigger: 'change' }],
                accountCity: [{ required: true, message: '请选择城市', trigger: 'change' }],
                bankName: [{ required: true, message: '请选择开户银行', trigger: 'change' }],
                branchName: [{ required: true, message: '请选择支行名称', trigger: 'change' }],
            }
        }
    },
    created() {
    },
    props: {
        provinceList: {
            type: Array,
            default: []
        },
        update: {
            type: Boolean,
            default: false
        },
        companyName: {
            type: String,
            default: ''
        },
        legalName: {
            type: String,
            default: ''
        },
        updateData: {
            type: [String, Object],
            default: {}
        },
        checkMsg: {
            type: String,
            default: ''
        }
    },
    watch: {
        updateData: {
            handler(val) {
                if (val && this.update) {
                    this.setterInfo = val
                    for (let i = 0; i < this.provinceList.length; i++) {
                        if (val.accountProvince == this.provinceList[i].code) {
                            this.cityList = this.provinceList[i].children
                        }
                    }
                }
            },
            immediate: true,
        },
        provinceList: {
            handler(val) {
                if (val.length>0 && this.update) {
                    for (let i = 0; i < this.provinceList.length; i++) {
                        if (this.setterInfo.accountProvince == this.provinceList[i].code) {
                            this.cityList = this.provinceList[i].children
                        }
                    }
                }
            },
            immediate: true,
        },
    },
    methods: {
        getAccountImg(fileList, filename, ocrData) {
            if (JSON.stringify(ocrData) != '{}') {
                this.$set(this.setterInfo, 'accountNo', ocrData.bank_card_number.replace(/\s*/g, ""))
                this.$set(this.setterInfo, 'bankName', ocrData.bank_name)
                if (ocrData.bank_name) {
                    this.remoteMethod(ocrData.bank_name, 'ocr')
                }
            }
            this.setterInfo.accountImg = filename
        },
        nextClick(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.setterInfo.accountType == '2') {
                        this.setterInfo.accountName = this.companyName
                    } else {
                        this.setterInfo.accountName = this.legalName
                    }
                    this.$emit('change', this.setterInfo, `${this.update ? 'submit' : 'next'}`)
                } else {
                    return false;
                }
            });
        },
        prevClick() {
            this.$emit('change', '', 'prev')
        },
        provinceChange(val) {
            this.setterInfo.accountCity = ''
            this.provinceList.map(e => {
                if (val == e.code) {
                    this.cityList = e.children
                }
            })
        },
        getAccountType() {
            if (this.setterInfo.accountType == '2') {
                this.accountTypeTxt = '银行开户许可证'

            } else {
                this.accountTypeTxt = '法人代表银行卡正面照片'
            }
        },
        async remoteMethod(query, ocr) {
            let res = await AgentApi.bankList({ name: query })
            if (res.success) {
                this.options = res.data
                if (ocr) {
                    this.setterInfo.bankName = this.options[0].name
                    this.setterInfo.bankCode = this.options[0].code
                    this.setterInfo.branchName = ''
                    this.setterInfo.cnapsNo = ''
                    this.branchOptions = []
                }
            }
        },
        async branchRemoteMethod(query) {
            if (query != '') {
                let params = {
                    provinceCode: this.setterInfo.accountProvince,
                    cityCode: this.setterInfo.accountCity,
                    bankCode: this.setterInfo.bankCode,
                    name: query,
                }
                let res = await AgentApi.branchList(params)
                if (res.success) {
                    this.branchOptions = res.data
                }
            } else {
                this.branchOptions = []
            }
        },
        checkPC() {
            if (!this.setterInfo.accountCity) {
                this.Message.error('开户行城市不能为空')
                return
            }
            if (!this.setterInfo.bankName) {
                this.Message.error('开户银行不能为空')
                return
            }
        },
        getCnaps(e) {
            this.setterInfo.branchName = e.name
            this.setterInfo.cnapsNo = e.code
        },
        getBank(e) {
            this.setterInfo.bankName = e.name
            this.setterInfo.bankCode = e.code
            this.setterInfo.branchName = ''
            this.setterInfo.cnapsNo = ''
            this.branchOptions = []
        },
    },
}
</script>